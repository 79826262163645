.row{
 margin:10px 5px   
}
.preference{    
    margin: 5px 0px;
}
.button{
    width: 150px;
}
.aligenCenter{
    display: flexbox;
}
